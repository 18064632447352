import { Image } from 'components/Image'
import { get as _get } from 'lodash-es'

/**
 * Creates JSX Element that displays image
 *
 * USAGE:
 * Option 1a: Image is fixed size -> pass in width and height as numbers
 * Option 1b: Image is fixed height but crops to fit container width -> pass only height
 * 		Option 1a and 1b both require param layout='fill', content and alt arguments.
 *
 * Option 2: Image fits width of container responsively and height maintains image aspect ratio.
 * 					 Only pass content and alt arguments.
 *
 * All options:
 * 		- Set border radius on MediaImageStandard element
 * 		- Padding/Margin must set to an additional container around the MediaImageStandard.
 *
 * @param {Object} content - Required, the image content object from Contentful
 * @param {string} [alt] - Optional but recommended, image alt attribute info
 * @param {number|string} [width='100%'] - Use with height if Layout='fill' and want fixed size
 * @param {number|string} [height='100%'] - Use if Layout='fill' and want fixed image height
 * @param {string} [layout='responsive'] - Either empty or 'fill'
 * @param {string} [objectFit='cover'] - Optional (only affects layout='fill')
 * @param {string} [objectPosition='center'] - Optional (only affects layout='fill')
 * @returns JSX
 */
export function MediaImageStandard({
  content,
  alt,
  width = '100%',
  height = '100%',
  layout = 'responsive',
  objectFit = 'cover',
  objectPosition = 'center',
  ...restProps
}) {
  const { file, title } = content?.fields || {}
  const urlWithProtocol = file?.url ? `https://${file.url}` : ''

  if (typeof width === 'number') width = `${width}px`
  if (typeof height === 'number') height = `${height}px`

  //Option 1: Image will fill container of width/height dimensions and center crop image to fit.
  if (layout === 'fill') {
    return (
      <div
        style={{
          position: 'relative',
          margin: 'auto',
          width: width,
          height: height,
          overflow: 'hidden',
        }}
        data-testid="media-image-container"
      >
        <Image
          src={urlWithProtocol}
          alt={alt || title}
          layout={layout}
          objectFit={objectFit}
          objectPosition={objectPosition}
          {...restProps}
        />
      </div>
    )
  }

  //Option 2: Keep image aspect ratio and display whole image responsively to fit its container.
  const imageHeight = _get(file, 'details.image.height')
  const imageWidth = _get(file, 'details.image.width')
  return (
    <Image
      src={urlWithProtocol}
      alt={alt || title}
      width={imageWidth}
      height={imageHeight}
      layout={layout}
      objectFit={objectFit}
      objectPosition={objectPosition}
      {...restProps}
    />
  )
}

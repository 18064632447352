import useMediaQuery from 'hooks/useMediaQuery'
import useTheme from 'hooks/useTheme'

/**
@see {@link https://bitbucket.org/billsdotcom_bills/sunbeam/src/main/src/styles/tokens/breakpoints/breakpoints.json | Sunbeam Breakpoints}
*/
const BREAKPOINTS = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg', // For launch, desktop designs begin at the `lg` breakpoint
  xl: 'xl',
  xxl: 'xxl',
}

/**
 * @deprecated 7/8/22 - this is staying around to avoid breaking changes. `useViewportLargerThan`
 * and `useViewportSmallerThan` are preferred.
 */
const useIsMobile = (breakpoint = BREAKPOINTS.lg) => {
  return useMediaQuery(useTheme().breakpoints.down(breakpoint))
}

/**
 * Check to see if a viewport is larger than a given breakpoint
 * @param {string} [breakpoint='xs'] - xs, sm, md, lg, xl, xxl
 * @returns boolean
 */
const useViewportLargerThan = (breakpoint = BREAKPOINTS.xs) => {
  return useMediaQuery(useTheme().breakpoints.up(breakpoint))
}

/**
 * Check to see if a viewport is smaller than a given breakpoint
 * @param {string} [breakpoint='xxl'] - xs, sm, md, lg, xl, xxl
 * @returns boolean
 */
const useViewportSmallerThan = (breakpoint = BREAKPOINTS.xxl) => {
  return useMediaQuery(useTheme().breakpoints.down(breakpoint))
}

export { useIsMobile, useViewportLargerThan, useViewportSmallerThan, BREAKPOINTS }

import { Image } from 'components/Image'
import { useEffect, useState } from 'react'
import { debounce as _debounce } from 'lodash-es'
import useMobileNavState from 'hooks/useMobileNavState'
import useIsSsr from 'hooks/useIsSsr'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { Typography } from '@ffn/sunbeam'
import Container from '@mui/material/Container'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { SignInLink } from 'components/SignInLink'
import Navigation from 'components/Navigation'
import HeaderMobileActions from './HeaderMobileActions'
import HeaderCta from './HeaderCta'
import styles from './Header.module.scss'

// Percentage of the window that needs to scroll for the mobile header to condense
const MOBILE_CONDENSED_THRESHOLD = 0.3

/**
 * Main site Header component
 * @param {{
 *  mainNavigation: object
 *  disabledRoutes: []
 * }} props
 */
export default function Header({ mainNavigation, disabledRoutes, disabledElements }) {
  const [mobileNavOpen, setMobileNavOpen] = useMobileNavState()
  const [condensed, setCondensed] = useState(false)
  const [mobileCondensed, setMobileCondensed] = useState(false)
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const isSsr = useIsSsr()

  const debounceOptions = {
    leading: true,
    trailing: false,
  }

  const setMobileCondensedTrue = _debounce(() => setMobileCondensed(true), 100, debounceOptions)
  const setMobileCondensedFalse = _debounce(() => setMobileCondensed(false), 100, debounceOptions)
  const setCondensedTrue = _debounce(() => setCondensed(true), 100, debounceOptions)
  const setCondensedFalse = _debounce(() => setCondensed(false), 100, debounceOptions)

  /**
   * Set the initial condensed state based on window scroll position
   */
  useEffect(() => {
    if (isSsr) {
      // no window to measure during SSR
      return
    }

    onScroll()
    // This effect is only intended to run on the first browser (not SSR) render
  }, [isSsr]) /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })

  function onScroll() {
    if (isMobile) {
      return handleMobileScroll()
    }
    return handleDesktopScroll()
  }

  /**
   * Enable the condensed mobile header when the page is scrolled passed the threshold
   */
  function handleMobileScroll() {
    const currentScroll = window.scrollY
    const nextMobileCondensed = currentScroll > window.innerHeight * MOBILE_CONDENSED_THRESHOLD

    if (nextMobileCondensed === mobileCondensed) {
      return
    }

    if (nextMobileCondensed) {
      return setMobileCondensedTrue()
    }

    return setMobileCondensedFalse()
  }

  /**
   * Enable the condensed desktop header when the page is scrolled any distance from the top
   */
  function handleDesktopScroll() {
    const currentScroll = window.scrollY
    const nextCondensed = Boolean(currentScroll)

    if (nextCondensed === condensed) {
      return
    }

    if (nextCondensed) {
      return setCondensedTrue()
    }

    return setCondensedFalse()
  }

  /*
    Prevent body scroll when the mobile navigation menu is open
  */
  useEffect(() => {
    const body = document.querySelector('body')

    if (!body) {
      return
    }

    if (isMobile && mobileNavOpen) {
      // Prevent the body scrolling under the open mobile nav menu by fixing the body element,
      // setting the height to the viewport height and setting the overflow to hidden
      body.style.position = 'fixed'
      body.style.height = '100vh'
      body.style.width = '100vw'
      body.style.overflow = 'hidden'
      return
    }

    // Reset the body css to scroll and overflow normally.
    body.style.position = 'initial'
    body.style.height = 'initial'
    body.style.width = 'initial'
    body.style.overflow = 'initial'
  }, [isMobile, mobileNavOpen])

  const handleCloseClick = () => {
    !isSsr && window.scrollTo({ top: 0, behavior: 'instant' })
    setMobileNavOpen(false)
  }

  return (
    <header
      className={styles.header}
      data-condensed={condensed}
      data-mobile-condensed={mobileCondensed}
      data-mobile-nav-open={mobileNavOpen}
      data-testid="acx-website-header"
    >
      <Container maxWidth={false} disableGutters className={styles['header-content-container']}>
        <div className={styles['header-content']}>
          {/* Container for content shown in the mobile header bar */}
          <div className={styles['mobile-header']}>
            <AchieveLink
              track={{
                nav_link: 'Header',
                click_type: 'Link Click',
                click_id: '2022_Achieve_Logo_RGB',
                track_event: 'global_header',
              }}
              href="/"
              withNextLink
            >
              <a className={styles['logo']} data-testid="achieve-header-logo">
                <Image
                  src="/2022_Achieve_Logo_RGB.svg"
                  alt="Achieve Logo"
                  layout="fill"
                  priority={true}
                />
              </a>
            </AchieveLink>

            <AchieveLink
              track={{
                nav_link: 'Header',
                click_type: 'Link Click',
                click_id: '2022_Achieve_Logomark_RGB',
                track_event: 'global_header',
              }}
              href="/"
              withNextLink
            >
              <a
                className={styles['mobile-logo']}
                data-testid="achieve-header-mobile-logo"
                onClick={() => setMobileNavOpen(false)}
              >
                <Image
                  src="/2022_Achieve_Logomark_RGB.svg"
                  alt="Achieve Mobile Logo"
                  layout="fill"
                  priority={true}
                />
              </a>
            </AchieveLink>

            {/* Mobile nav menu open and close buttons */}
            <div className={styles['mobile-menu-controls']}>
              <button
                onClick={() => setMobileNavOpen(true)}
                className={styles['mobile-menu-button']}
                data-testid="mobile-nav-open-button"
                aria-label="Main navigation menu button"
              >
                {/*
                  When using next/image, on some devices, including iPhone Xs,
                  the hamburger icon is small and it disappears after tapping close.
                */}
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img src="/menu.svg" alt="Menu button" width={18} height={12} />
              </button>
              <button
                onClick={handleCloseClick}
                className={styles['mobile-close-button']}
                data-testid="mobile-nav-close-button"
                aria-label="Main navigation menu close button"
              >
                <Typography variant="bodyS20" fontWeight="medium" component="span">
                  CLOSE
                </Typography>
                <Image src="/close.svg" alt="Close button" width={12} height={12} priority={true} />
              </button>
            </div>
          </div>

          {/* Main nav and sub nav list components */}
          <Navigation
            condensed={condensed}
            mobileNavOpen={mobileNavOpen}
            items={mainNavigation}
            disabledRoutes={disabledRoutes}
            disabledElements={disabledElements}
          />
          <div>
            {/* "Get Started" CTA button is always present in desktop and is hidden and shown on
          scroll in mobile. */}
            <HeaderCta
              size={isMobile || condensed ? 'xsmall' : 'small'}
              className={isMobile ? styles['mobile-header-cta'] : styles['header-cta']}
              data-testid="header-get-started-button"
            />

            <SignInLink
              className={styles['sign-in-link']}
              data-testid="header-sign-in-link"
              disabledRoutes={disabledRoutes}
            />
          </div>

          {/* Action bar that shows at the bottom of the mobile nav */}
          {isMobile && <HeaderMobileActions disabledRoutes={disabledRoutes} />}
        </div>
      </Container>
    </header>
  )
}

import { Fragment } from 'react'
import { Image } from 'components/Image'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import Container from '@mui/material/Container'
import Typography from 'components/Contentful/Typography'
import { Typography as SunbeamTypography, Grid } from '@ffn/sunbeam'
import ScrollToTopButton from 'components/ScrollToTopButton'
import styles from './Footer.module.scss'

const currentYear = new Date().getFullYear()

/* Social Icons will be hidden for initial launch
function SocialIcons() {
  return (
    <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
      <Grid item>
        <Link href="/">
          <a className={styles['social-icon']} data-testid="twitter-icon">
            <Image src="/twitter-icon.svg" alt="Twitter Icon" layout="fill" />
          </a>
        </Link>
      </Grid>
      <Grid item>
        <Link href="/">
          <a className={styles['social-icon']} data-testid="linkedin-icon">
            <Image src="/linkedin-icon.svg" alt="LinkedIn Icon" layout="fill" />
          </a>
        </Link>
      </Grid>
    </Grid>
  )
}
*/

export default function Footer({ footerNavigation = [], pageDisclosure, disabledRoutes }) {
  return (
    <footer className={styles['footer']}>
      <Container maxWidth={false} disableGutters className={styles['footer-content-container']}>
        <div className={styles['footer-row']}>
          <div className={styles['footer-section']}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={styles['footer-brand-icon-row']}
            >
              <Grid item>
                <AchieveLink
                  href="/"
                  withNextLink
                  track={{
                    nav_link_section: 'Footer Click',
                    click_type: 'Link Click',
                    click_id: '2022_Achieve_Logomark_RGB',
                    track_event: 'global_footer',
                  }}
                >
                  <a className={styles['footer-logo']} data-testid="achieve-logo">
                    <Image
                      src="/2022_Achieve_Logomark_RGB.svg"
                      alt="Achieve Logomark"
                      layout="fill"
                      priority={true}
                    />
                  </a>
                </AchieveLink>
              </Grid>
              <Grid item>
                <ScrollToTopButton
                  className={styles['scroll-to-top']}
                  data-testid="home-scroll-to-top-button"
                />
              </Grid>
            </Grid>
            <SunbeamTypography component="p" variant="bodyS30" fontWeight="regular">
              Achieve is the leader in digital personal finance, built to help everyday people move
              forward on the path to a better financial future.
            </SunbeamTypography>
          </div>

          {footerNavigation.map(({ menuLabel, menuItems = [] }, menuIndex) => (
            <nav className={styles['footer-section']} key={menuIndex} data-testid="footer-menus">
              <SunbeamTypography component="h4" fontWeight="bold" variant="bodyS30">
                {menuLabel}
              </SunbeamTypography>
              <ul>
                {menuItems?.map(({ label, testid, url }, menuItemIndex) => (
                  <Fragment key={menuItemIndex}>
                    {!disabledRoutes.includes(url) && (
                      <li>
                        <AchieveLink
                          href={url}
                          withNextLink
                          track={{
                            nav_link_section: 'Footer Click',
                            click_type: 'Link Click',
                            click_id: label,
                            track_event: 'global_footer',
                          }}
                        >
                          <a data-testid={testid}>
                            <SunbeamTypography variant="bodyS30" fontWeight="regular">
                              {label}
                            </SunbeamTypography>
                          </a>
                        </AchieveLink>
                      </li>
                    )}
                  </Fragment>
                ))}
              </ul>
            </nav>
          ))}
        </div>

        {pageDisclosure && (
          <div className={styles['footer-disclosure']} data-testid="footer-page-disclosure">
            <Typography content={pageDisclosure} variant="displayXS10" fontWeight="regular" />
          </div>
        )}
        <div className={styles['footer-copyright-row']}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <SunbeamTypography
                variant="bodyS30"
                fontWeight="regular"
                className={styles['footer-copyright']}
              >
                &copy; {currentYear} Bills.com, LLC. All rights reserved.
              </SunbeamTypography>
            </Grid>
            {/* Social Icons will be hidden for initial launch
            <Grid item>
              <SocialIcons />
            </Grid> */}
          </Grid>
        </div>
      </Container>
    </footer>
  )
}

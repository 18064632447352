const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const iOSSafariMobile = (userAgent) => {
  console.log('userAgent: ', userAgent)
  return (
    (/iP(ad|od|hone)/i.test(userAgent) &&
      /WebKit/i.test(userAgent) &&
      !/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent)) ||
    // iPad on iOS 13 detection
    (userAgent.includes('Mac') && 'ontouchend' in document)
  )
}
export { scrollToTop, iOSSafariMobile }

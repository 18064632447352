import Container from '@mui/material/Container'
import styles from './Section.module.scss'
import classNames from 'classnames'

function Section({
  children,
  backgroundColor,
  childContainerClassName,
  className,
  contain = true,
  ...containerProps
}) {
  const contents = contain ? (
    <Container
      maxWidth={false}
      disableGutters
      className={classNames(styles['achieve-section-container'], childContainerClassName)}
      {...containerProps}
    >
      {children}
    </Container>
  ) : (
    children
  )

  return (
    <section style={{ backgroundColor }} className={className}>
      {contents}
    </section>
  )
}

export default Section

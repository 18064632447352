import Head from 'next/head'
import { getFeatureDisabledRoutes, getFeatureDisabledElements } from 'utils/next/routes'
import { LayoutProvider } from 'providers/LayoutProvider'
import { HeaderProvider } from 'providers/HeaderProvider'
import Footer from 'components/Footer'
import navData from 'components/Navigation/navigation.json'
import footerNavigation from 'components/Footer/footerNavigation.json'
import Header from 'components/Header'
import styles from 'components/Layout/Layout.module.scss'
import { useRouter } from 'next/router'

export default function Layout({
  canonicalLink,
  children,
  featureToggleObject,
  pageDisclosure = '',
  pageMetaData = [],
  pageMetaTitle,
}) {
  const disabledRoutes = getFeatureDisabledRoutes(featureToggleObject)
  const disabledElements = getFeatureDisabledElements(featureToggleObject)

  const router = useRouter()

  return (
    <>
      <Head data-testid="layout-head">
        <title data-testid="layout-title">
          {pageMetaTitle
            ? `${pageMetaTitle} ${router.pathname === '/' ? '' : '| Achieve'}`
            : `Achieve`}
        </title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" data-testid="layout-head-canonical-link" href={canonicalLink} />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta charSet="UTF-8" />
        {pageMetaData
          .filter(({ fields: { name } }) => name !== 'viewport')
          .map(({ fields: { name, content } }) => (
            <meta key={name} name={name} content={content} />
          ))}
      </Head>

      <div className={styles['max-width-container']}>
        <LayoutProvider>
          <HeaderProvider>
            <Header
              mainNavigation={navData.mainNavigation}
              disabledRoutes={disabledRoutes}
              disabledElements={disabledElements}
            />
          </HeaderProvider>
          <main className={styles.main}>{children}</main>
          <Footer
            footerNavigation={footerNavigation.footerMenus}
            pageDisclosure={pageDisclosure}
            disabledRoutes={disabledRoutes}
          />
        </LayoutProvider>
      </div>
    </>
  )
}

import React from 'react'
import NextLink from 'next/link'
import { Link as SunbeamLink } from '@ffn/sunbeam'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { TrackingWrapper } from 'components/Tracking/TrackingWrapper'

const IGNORE_LIST = ['slug']

const preserveQueryParams = (href, query) => {
  try {
    // href = /get-started?brand=achieve
    const [hrefWithAnchor, hrefQuery] = href.split('?')
    const [hrefOnly, anchorOnly] = hrefWithAnchor.split('#')

    // brand=achieve
    const hrefQueries = new URLSearchParams(hrefQuery)

    // ['utm_params', 'qa-test']
    const routerQueryEntries = new URLSearchParams(query).entries()
    // remove query params added by dynamic routes
    // https://nextjs.org/docs/routing/dynamic-routes#caveats
    const dynamicParamsToIgnore = new Set(IGNORE_LIST)

    for (const [key, value] of routerQueryEntries) {
      if (!dynamicParamsToIgnore.has(key)) {
        // overwrite any queries by the same key
        hrefQueries.set(key, value)
      }
    }

    if (!href.includes('?') && hrefQueries.toString() === '') return href

    // result => /get-started?brand=achieve&utm_params=qa-test#Privacy-Policy
    return `${hrefOnly}?${hrefQueries.toString()}${anchorOnly ? '#' + anchorOnly : ''}`
  } catch (e) {
    console.error('Error parsing href and query: ', href, query)
    return href
  }
}

const LinkWrapper = React.forwardRef(({ children, refAs, ...props }, ref) => {
  if (refAs) {
    props[refAs] = ref
  }

  return (
    <>
      {React.isValidElement(children)
        ? React.cloneElement(children, props)
        : typeof children === 'string'
        ? children
        : null}
    </>
  )
})

LinkWrapper.displayName = 'LinkWrapper'

/**
 *
 * @param {string} href original href string
 * @param {ReactNode} children child components wrapped with AchieveLink
 * @param {boolean} withNextLink if true use next/link, default use SunbeamLink
 * @param {RefObject} refAs external ref to be used in the link child
 * @returns {ReactElement} returns link wrapped child component with forwarded
 *                         ref, passed props and transformed/memoized href
 *                         with original query params preserved
 */

const AchieveLink = ({
  href,
  children,
  withNextLink = false,
  refAs,
  track,
  onClick,
  ...restProps
}) => {
  const { query = {} } = useRouter()
  const memoHref = useMemo(() => preserveQueryParams(href, query), [href, query])

  const LinkComponent = withNextLink ? NextLink : SunbeamLink
  const nextProps = withNextLink ? { passHref: true } : {}

  return memoHref[0] === '/' ? (
    <TrackingWrapper
      track={{ href: memoHref, ...track }}
      onClick={onClick}
      withNextLink={withNextLink}
    >
      <LinkComponent href={memoHref} {...restProps} {...nextProps}>
        {children?.type === 'a' ? children : <LinkWrapper refAs={refAs}>{children}</LinkWrapper>}
      </LinkComponent>
    </TrackingWrapper>
  ) : (
    <LinkComponent href={memoHref} {...restProps} {...nextProps}>
      {children?.type === 'a' ? children : <LinkWrapper refAs={refAs}>{children}</LinkWrapper>}
    </LinkComponent>
  )
}

export { AchieveLink }

/**
 * As of 8/19/2022, the only used constant here is FEATURE_TOGGLES.ACX_WEB_ENABLE_PRESS and
 * the rest is copypasta from another project. No harm in keeping them here since we might use the
 * same patterns, but if they begin to conflict or will end up being unused then they can be removed.
 */

export const FEATURE_TOGGLES = {
  ACX_EXPERIMENT: 'acx-experiment-mvp',
  ACX_WEB_ENABLE_CAREERS: 'acx-web-enable-careers',
  ACX_WEB_ENABLE_LICENSES: 'acx-web-enable-licenses',
  ACX_WEB_ENABLE_PRESS: 'acx-web-enable-press-routes',
  ACX_WEB_ENABLE_SIGN_IN: 'acx-web-enable-sign-in',
  ACX_WEB_ENABLE_NAV_TITLES: 'acx-web-enable-nav-titles',
  ACX_WEB_ENABLE_MEMBER_STORIES: 'acx-web-enable-member-stories',
}

export const EXPERIMENT_NAMES = {
  [FEATURE_TOGGLES.ACX_EXPERIMENT]: 'ACX Experiment',
}

export const EVENT_TYPES = {
  START: 'start',
  CONVERSION: 'conversion',
  TRACK: 'track',
}

// values should be all lower case
export const VARIATIONS = {
  CONTROL: 'control',
  VARIATION_A: 'variation_a',
  VARIATION_B: 'variation_b',
  VARIATION_C: 'variation_c',
  UNASSIGNED: 'unassigned',
  OFF: 'off',
}

export default FEATURE_TOGGLES
